<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="公司名称"
          prop="company"
          v-bind="formItemLayout"
      >
        <a-input
            v-model="form.company"
            allow-clear
            placeholder="请输入公司名称"
        />
      </a-form-model-item>
      <a-form-model-item label="公司全称" v-bind="formItemLayout" prop="standardName">
        <a-input v-model="form.standardName" v-decorator="['standardName']" allow-clear placeholder="请输入公司全称"/>
      </a-form-model-item>
      <a-form-model-item label="统一编码" v-bind="formItemLayout" prop="bizCreditNo">
        <a-input v-model="form.bizCreditNo" v-decorator="['bizCreditNo']" allow-clear placeholder="请输入统一社会信用代码"/>
      </a-form-model-item>
      <a-form-model-item label="法定代表人" v-bind="formItemLayout" prop="legalPerson">
        <a-input v-model="form.legalPerson" v-decorator="['legalPerson']" allow-clear placeholder="请输入法定代表人"/>
      </a-form-model-item>
      <a-form-model-item label="公司地址" v-bind="formItemLayout" prop="address">
        <a-input v-model="form.address" v-decorator="['address']" allow-clear placeholder="请输入详细地址（包括省市区）"/>
      </a-form-model-item>
      <a-form-model-item label="经营范围" v-bind="formItemLayout" prop="bizScope">
        <a-textarea v-model="form.bizScope" rows="8"/>
      </a-form-model-item>
      <!--      <a-form-model-item label="状态" v-bind="formItemLayout">-->
      <!--        <a-radio-group v-model="form.status">-->
      <!--          <a-radio :value="1">已审核</a-radio>-->
      <!--          <a-radio :value="0">待审核</a-radio>-->
      <!--          <a-radio :value="-1">未通过</a-radio>-->
      <!--        </a-radio-group>-->
      <!--      </a-form-model-item>-->
      <a-form-model-item ref="doorHeader" label="上传营业执照" v-bind="formItemLayout">
        <div class="clearfix">
          <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              withCredentials
              :multiple="false"
              :data="{ bucketType: 'public', uploadType: 'image' }"
              :headers="headers"
              :file-list="imageList"
              action="admin/upload/uploadImage"
              :before-upload="beforeUpload"
              @preview="handlePreview"
              @change="(info) => handleImageChange(info)"
          >
            <div v-if="imageList.length < 1">
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <!--  -->
          <a-modal
              v-drag-modal
              title="图片预览"
              :visible="previewVisible"
              :footer="null"
              @cancel="previewVisible = false"
          >
            <img alt="example" style="width: 100%" :src="previewImage"/>
          </a-modal>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
function getBase64OfPreview(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "modifyCompanyModal",
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 4},
        wrapperCol: {span: 17},
      },
      mode: 'new',
      title: "",
      visible: false,
      confirmLoading: false,
      previewVisible: false,
      form: {},
      imageList: [],
      cateList: [],
      previewImage: "",
      rules: {
        company: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        // city: [
        //   {
        //     required: true,
        //     message: "请输入城市名称（不需要带后缀市）",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  computed: {
    headers() {
      return {
        Authentication: this.$store.state.account.token,
      };
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    handleImageChange(info) {
      this.imageList = info.fileList || [];
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      this.loading = false;
    },
    beforeUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("只能支持JPEG/PNG/GIF图片!");
      }
      const isLt2M = file.size / 1024 / 1024 <= 3;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过3MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview && !file.thumbUrl) {
        file.preview = await getBase64OfPreview(file.originFileObj);
      }
      this.previewImage = file.url || file.preview || file.thumbUrl;
      this.previewVisible = true;
    },
    showModal(record, mode) {
      const that = this;
      let array = [];

      this.visible = true;
      this.mode = mode || 'new'
      if (this.mode == 'new') {

        this.title = "创建新公司";
        this.form = {
          status: 1,
          isTop: 0,
        };
      } else {
        this.title = "修改公司：" + record.company;
        this.form = {
          ...record,
        };

        let imageList = [record.logo];

        imageList.forEach((v) => {
          if (!v) return;
          let path = that.$helper.getFullImgPath(v);
          array.push({
            type: "image/png",
            name: v,
            uid: v,
            status: "done",
            thumbUrl: path,
            response: {data: path, code: 0},
          });
        });
      }
      this.imageList = array;
    },

    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          if (this.imageList.length) {
            this.form.bizCreditImg = this.imageList.map((item) => {
              return item.response.data;
            })[0];
          }
          this.$postJson("company/update", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("修改成功");
            this.$emit("success");
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
